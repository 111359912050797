import React, { useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import LightButton from 'shared/components/light-button'
import { limitObj_10_25_50 } from 'shared/route-query/constants/pagination-limit-array'
import { LimitObjType, LimitType, OrderEnum } from 'shared/types/swr-pagination-interface'
import { TableProps } from './table'

export interface DataWithPaginationInterface<T> {
  hasMore: boolean
  items: T[]
}

export interface PaginationInterface {
  startFromId?: number | string
  order: OrderEnum
  hasMore?: boolean
  isFirst?: boolean
  staticUrl?: boolean
}

export interface TableLimitPaginationProps<T>
  extends Pick<TableProps<T>, 'data' | 'limiter' | 'paginator'> {
  limitObj?: LimitObjType
}

const TableLimitPagination = <T extends { id: number | string }>({
  data,
  limiter,
  paginator,
  limitObj = limitObj_10_25_50,
}: TableLimitPaginationProps<T>) => {
  const { t } = useTranslation()
  const router = useRouter()
  const { pagination, setPagination } = paginator!
  const { limit, setLimit } = limiter!
  const { limit: limitAmount } = router.query

  const isPaginationShown =
    (data &&
      limiter &&
      paginator &&
      ((paginator.pagination.order !== 'prev' && paginator.hasMore) ||
        paginator.pagination.order === 'prev' ||
        !paginator.pagination.isFirst)) ||
    (data && limiter && limiter.limit && limiter.limit !== limitObj.short)

  useEffect(() => {
    if (paginator) {
      paginator.setPagination(prev => ({
        ...prev,
        hasMore: paginator.hasMore,
        isFirst: (prev.order === 'prev' && !paginator.hasMore) || prev.startFromId === undefined,
      }))
    }
  }, [data])

  useEffect(() => {
    if (limitAmount) {
      setLimit(Number(limitAmount) as LimitType)
    } else {
      setLimit(limitObj.short)
    }
  }, [limitAmount, setLimit, limitObj.short])

  useEffect(() => {
    if (pagination.startFromId && data && !pagination.staticUrl) {
      router.push(
        {
          query: {
            limit: limit,
            startFromId: pagination.startFromId,
            order: pagination.order,
          },
        },
        undefined,
        {
          shallow: true,
        },
      )
    } else if (data && !pagination.startFromId && !pagination.staticUrl) {
      router.push(
        {
          query: {
            limit: limit,
          },
        },
        undefined,
        {
          shallow: true,
        },
      )
    }
  }, [pagination, limit, data])

  return (
    <>
      <div className={`${isPaginationShown ? 'flex' : 'hidden'} gap-2`}>
        {Object.values(limitObj).map(el => (
          <button
            key={el}
            className={`flex h-9 w-9 cursor-pointer items-center justify-center rounded-full text-sm font-bold outline-none ring-blue ring-offset-1 focus-visible:ring-2 ${
              limit === el ? 'bg-white text-blue' : 'bg-transparent text-darkblue/50'
            }`}
            disabled={data === undefined || data.items.length === 0}
            onClick={() => {
              setLimit(el)
              setPagination(prev => ({
                ...prev,
                startFromId: undefined,
                order: OrderEnum.next,
              }))
            }}
          >
            {el}
          </button>
        ))}
      </div>
      <div className={`${isPaginationShown ? 'flex' : 'hidden'} gap-3`}>
        <LightButton
          disabled={
            data === undefined ||
            data.items.length === 0 ||
            pagination.isFirst ||
            (pagination.order === 'prev' && !pagination?.hasMore)
          }
          onClick={() =>
            setPagination(prev => ({
              ...prev,
              startFromId: data?.items[0].id || undefined,
              order: OrderEnum.prev,
            }))
          }
        >
          {t('global.previous')}
        </LightButton>
        <LightButton
          disabled={
            data === undefined ||
            data.items.length === 0 ||
            (!pagination.hasMore && (pagination.order === 'next' || pagination.order === undefined))
          }
          onClick={() =>
            setPagination(prev => ({
              ...prev,
              isFirst: false,
              startFromId: data?.items[data.items.length - 1].id || undefined,
              order: OrderEnum.next,
            }))
          }
        >
          {t('global.next')}
        </LightButton>
      </div>
    </>
  )
}

export default TableLimitPagination
